
import React, { Component, Fragment } from 'react';
import Modal from "react-modal";
import { Colors } from '../../constants';
import LoadingSpinner from '../LoadingSpinner';

const customStyles = {
    content: {
        // top: "50%",
        // left: "50%",
        // right: "auto",
        // bottom: "auto",
        // marginRight: "-50%",
        // transform: "translate(-50%, -50%)",
        // minWidth: "40%",
        // maxHeight: "75vh",
        maxHeight: "90%",
        zindex: 9999999
    },
};

const STEPS = {
    CONFIRMATION_REQUEST_STEP: 1,
    FREELANCER_DETAILS_STEP: 2,
    DECLINED_STEP: 3
}

class FillPDFFReelancerModal extends Component {

    state = {
        step: STEPS.CONFIRMATION_REQUEST_STEP,
        declineReason: "",
        activeFreelancerStep: 1,
        loading: false
    }

    sendDecline = () => {
        this.props.declinedSignature(this.state.declineReason)
    }

    sendApproval = () => {
        this.setState({ loading: true })
        this.props.closeEditTextModal();
    }

    setLoadingFalse = () => {
        this.setState({ loading: false })
    }

    resetForm = () => {
        this.setState({ step: STEPS.CONFIRMATION_REQUEST_STEP, declineReason: "", activeFreelancerStep: 1, loading: false })
    }

    renderConfirmationStep = () => {
        return (
            <div className='text-center'>
                <label
                    className="labelModal"
                    style={{ direction: "rtl", fontWeight: "bold", fontSize: 17, textAlign: "center" }}>
                    האם אתה מאשר את המסמך?
                </label>
            </div>
        )
    }

    renderConfirmationButtons = () => {
        return (
            <div className='d-flex justify-content-center align-items-center buttons-responsive' style={{ marginTop: 20 }}>

                <input
                    type="button"
                    className="btn blue-button btn-same-width btn-document-header"
                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 0 }}
                    value="כן"
                    onClick={() => this.setState({ step: STEPS.FREELANCER_DETAILS_STEP })}
                />

                <input
                    type="button"
                    className="btn blue-button btn-same-width btn-document-header"
                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17 }}
                    value="לא"
                    onClick={() => this.setState({ step: STEPS.DECLINED_STEP })}
                />
            </div>
        )
    }

    getInitialFreelancerStep = () => {
        let activeFreelancerStep = 1;

        return activeFreelancerStep;
    }

    getIsMultipleCategories = () => {
        if (this.props.isOrg || this.props.isExternalOrg || this.props.isExternalApproval) {
            //return this.props.organizationCategories.length > 1;
            return false;
        } else {
            return this.props.freelancerCategories.length > 1;
        }
    }

    getActiveCategory = () => {
        if (this.props.isOrg) {
            //return this.props.organizationCategories[this.state.activeFreelancerStep - 1];
            return this.props.userInfoObj.organizationType;
        } else if (this.props.isExternalOrg || this.props.isExternalApproval) {
            return this.props.userInfoObj.category;
        } else {
            return this.props.freelancerCategories[this.state.activeFreelancerStep - 1];
        }
    }

    getIsNextButtonPresent = () => {
        if (!this.getIsMultipleCategories()) return false;

        if (this.props.isOrg || this.props.isExternalOrg || this.props.isExternalApproval) return false;

        //let len = this.props.isOrg ? this.props.organizationCategories.length : this.props.freelancerCategories.length;

        return this.state.activeFreelancerStep !== this.props.freelancerCategories.length;
    }

    renderFillDetailsStep = () => {
        return (
            <Fragment>
                {this.getIsMultipleCategories() && (
                    <div className='text-center' style={{ marginBottom: 15 }}>
                        <label
                            className="labelModal"
                            style={{ direction: "rtl", fontWeight: "bold", fontSize: 17 }}
                        >
                            {this.getActiveCategory()}
                        </label>
                    </div>
                )}

                {/* eslint-disable-next-line */}
                {this.props.options
                    .map((option, index) => {
                        let seperator = "";
                        if (this.props.isOrg) {
                            seperator = "+";
                        } else if (this.props.isExternalOrg) {
                            seperator = "@";
                        } else if (this.props.isExternalApproval) {
                            seperator = "<";
                        } else {
                            seperator = "_"
                        }
                        let currentLabelString = option.originalLabel.substring(option.originalLabel.lastIndexOf(seperator) + 1);

                        if (currentLabelString !== this.getActiveCategory()) {
                            return null;
                        }

                        if (option.label !== "" && option.isPresent !== false && !option.isHidden) {

                            let isDateField = option.label.includes("תאריך");
                            let currentValue = option.value;

                            if (option.label.includes("תאריך")) {
                                let split = currentValue.split("/");
                                let date = split[0];
                                let month = split[1];
                                let year = split[2];

                                currentValue = year + "-" + month + "-" + date;
                            }

                            return (
                                <div className="form-group" key={index}>
                                    <label>{option.label}</label>
                                    <input
                                        type={isDateField ? "date" : "text"}
                                        name={option.label}
                                        className={`form-control form-control-user ${isDateField ? "dt-input" : ""}`}
                                        style={{ textAlign: "right" }}
                                        id={option.label}
                                        aria-describedby="emailHelp"
                                        placeholder={option.label}
                                        onChange={(e) =>
                                            this.props.onChangeDynamictext(e, option.originalLabel)
                                        }
                                        value={currentValue}

                                    />

                                </div>
                            )
                        } else {
                            return null;
                        }

                    })}
                {/* eslint-disable-next-line */}
                {this.props.optionsSignature
                    .map((option, index) => {
                        let seperator = "";
                        if (this.props.isOrg) {
                            seperator = "+";
                        } else if (this.props.isExternalOrg) {
                            seperator = "@";
                        } else if (this.props.isExternalApproval) {
                            seperator = "<";
                        } else {
                            seperator = "_"
                        }

                        if (option.originalLabel.substring(option.originalLabel.lastIndexOf(seperator) + 1) !== this.getActiveCategory()) {
                            return null;
                        }

                        if (option.label !== "" && option.isPresent !== false && !option.isHidden) {
                            return (
                                <div className="form-group text-center" key={index}>
                                    <label>{option.label}</label>
                                    <div>
                                        <button name={option.label} value={option.label} onClick={(e) => { this.props.binddynamicSignautre(e, option) }} className="btn blue-button btn-same-width btn-document-header">
                                            {option.isFilled ? "שנה חתימה" : "חתום כאן"}
                                        </button>
                                        {option.isFilled && <div>
                                            <label style={{ fontSize: 13, color: "green" }}>נחתם בהצלחה</label>
                                        </ div>
                                        }
                                    </div>
                                </div>
                            )
                        }

                        return null;
                    })}


                {this.props.certificateCategory && this.props.certificateCategory.length > 0 && (
                    <div className="form-group text-center">
                        <label>{`העלאת תעודה של ${this.props.certificateCategory}`}</label>
                        <div>
                            <input type="file" id="fileUpload" style={{ visibility: "collapse", width: 0 }} onChange={this.props.fileChanged} />

                            <button onClick={() => document.getElementById("fileUpload").click()} className="btn blue-button btn-same-width btn-document-header">
                            {this.props.file ? 'החלפת תעודה' : 'העלאת תעודה'}
                            </button>

                            {this.props.file && (
                                <div>
                                    <label style={{ fontSize: 13, color: "green" }}>התעודה הועלאה בהצלחה</label>
                                </ div>
                            )}
                        </div>
                    </div>
                )}

                {this.props.errorMessage && (
                    <div className='text-right' style={{ marginTop: 30 }}>
                        <label style={{ fontSize: 14, color: "red" }}>{this.props.errorMessage}</label>
                    </div>
                )}
            </Fragment>
        )
    }

    renderFillDetailsButtons = () => {

        return (
            <div className="text-center w-100 d-flex justify-content-center align-items-center buttons-responsive" style={{ marginTop: 40 }}>

                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        {
                            (this.getIsNextButtonPresent()) ? (
                                <input
                                    type="button"
                                    className="btn blue-button btn-same-width btn-document-header"
                                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 0 }}
                                    value="הבא"
                                    onClick={() => this.setState({ activeFreelancerStep: this.state.activeFreelancerStep + 1 })}
                                />
                            ) : (
                                <input
                                    type="button"
                                    className="btn blue-button btn-same-width btn-document-header"
                                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 0 }}
                                    value="אישור וסיום"
                                    onClick={() => this.sendApproval()}
                                />
                            )
                        }

                        {
                            (this.getIsMultipleCategories() && this.state.activeFreelancerStep !== 1) && (
                                <input
                                    type="button"
                                    className="btn blue-button btn-same-width btn-document-header"
                                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 5 }}
                                    value="הקודם"
                                    onClick={() => this.setState({ activeFreelancerStep: this.state.activeFreelancerStep - 1 })}
                                />
                            )
                        }

                        <input
                            type="button"
                            className="btn blue-button btn-same-width btn-document-header"
                            style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17 }}
                            value="חזרה"
                            onClick={() => this.setState({ step: STEPS.CONFIRMATION_REQUEST_STEP })}
                        />
                    </div>
                )
                }
            </div>
        )
    }

    renderDeclinedStep = () => {
        return (
            <div className='text-center'>
                <label
                    className="labelModal"
                    style={{ direction: "rtl", fontWeight: "bold", fontSize: 17 }}>
                    נשמח לדעת למה אתה מסרב לחתום על המסמך
                </label>

                <div>
                    <span style={{ direction: "rtl", marginTop: 10 }}>
                        * הסיבה שתציין תשלח במייל ליזם
                    </span>
                </div>

                <div style={{ marginTop: 30 }}>
                    <textarea
                        cols={50}
                        rows={6}
                        placeholder='אני מסרב לחתום כי ...'
                        className="form-control form-control-user"
                        style={{ textAlign: "right" }}
                        value={this.state.declineReason}
                        onChange={(e) => this.setState({ declineReason: e.target.value })}>

                    </textarea>
                </div>

                {this.props.declineReasonErrorMessage && (
                    <div className='text-right' style={{ marginTop: 30 }}>
                        <label style={{ fontSize: 14, color: "red" }}>{this.props.declineReasonErrorMessage}</label>
                    </div>
                )}
            </div>
        )
    }

    renderDeclineButtons = () => {
        return (
            <div className="text-center w-100 d-flex justify-content-center align-items-center buttons-responsive" style={{ marginTop: 40 }}>
                <input
                    type="button"
                    className="btn blue-button btn-same-width btn-document-header"
                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 0 }}
                    value="שליחת תשובה"
                    onClick={() => this.sendDecline()}
                />

                <input
                    type="button"
                    className="btn blue-button btn-same-width btn-document-header"
                    style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17 }}
                    value="חזרה"
                    onClick={() => this.setState({ step: STEPS.CONFIRMATION_REQUEST_STEP })}
                />
            </div>
        )
    }

    renderActiveStep = () => {
        let { step } = this.state;

        switch (step) {
            case STEPS.FREELANCER_DETAILS_STEP:
                return this.renderFillDetailsStep();
            case STEPS.DECLINED_STEP:
                return this.renderDeclinedStep();

            case STEPS.CONFIRMATION_REQUEST_STEP:
            default:
                return this.renderConfirmationStep();
        }
    }

    renderButtons = () => {
        let { step } = this.state;

        switch (step) {
            case STEPS.FREELANCER_DETAILS_STEP:
                return this.renderFillDetailsButtons();
            case STEPS.DECLINED_STEP:
                return this.renderDeclineButtons();

            case STEPS.CONFIRMATION_REQUEST_STEP:
            default:
                return this.renderConfirmationButtons();
        }
    }

    render() {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={this.props.isVisible}
                style={customStyles}
                className={"modal-fill-freelancer"}
            >
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div></div>
                        <div>
                            <label
                                className="labelModal"
                                style={{ direction: "rtl", fontWeight: "bold", fontSize: 17 }}
                            >
                                חתימה ואישור
                            </label>
                        </div>
                        <div>
                            <i
                                className="fa fa-times pointer"
                                onClick={this.props.closeModal}
                            ></i>
                        </div>
                    </div>

                    <div className='step-container' style={{ direction: "rtl", marginTop: 20, textAlign: "right" }}>
                        {this.renderActiveStep()}
                        {this.renderButtons()}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default FillPDFFReelancerModal;

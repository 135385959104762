import React, { Component, Fragment } from 'react';
import Modal from "react-modal";
import { Colors, EXTERNAL_APPROVALS, EXTERNAL_ORGS, ORGANIZATION_TYPES } from '../../constants';
import { FREELANCERS_FIELDS, FREELANCER_AND_ORG_FIELDS, ORGANIZATIONS_FIELDS } from '../../constants/documentFields';


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "20%",
        zindex: 9999999
    },
};

class AddTextModal extends Component {

    render() {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={this.props.isVisible}
                style={customStyles}
            >
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div></div>
                        <div>
                            <label
                                className="labelModal"
                                style={{ direction: "rtl", fontWeight: "bold", fontSize: 17 }}
                            >
                                הוספת תיבת טקסט
                            </label>
                        </div>
                        <div>
                            <i className="fa fa-times pointer"
                                onClick={this.props.closeModal}></i>
                        </div>
                    </div>

                    <div
                        style={{ direction: "rtl", marginTop: 20, textAlign: "right" }}
                    >
                        <Fragment>


                            <div className="form-group">
                                <input
                                    type="text"
                                    name="text"
                                    className="form-control form-control-user"
                                    style={{ textAlign: "right" }}
                                    id="first_name"
                                    aria-describedby="emailHelp"
                                    placeholder="שם השדה"
                                    onChange={(e) => this.props.setLabelPSPDFKITAndFieldName(e.target.value)}
                                    value={this.props.labelPSPDFKIT}
                                />

                            </div>
                            <div className="form-group">

                                <input
                                    type="text"
                                    name="text"
                                    hidden
                                    className="form-control form-control-user"
                                    style={{ textAlign: "right" }}
                                    id="last_name"
                                    aria-describedby="emailHelp"
                                    placeholder="שם השדה"
                                    // onChange={(e) =>
                                    //   this.setState({ fieldNamePSPDFKIT: e.target.value })
                                    // }
                                    defaultValue={this.props.fieldNamePSPDFKIT}
                                />
                            </div>
                            <div className="form-group">
                                <select className="form-control form-control-user" value={this.props.binddynamicSelect} onChange={this.props.bindhandleChange}>
                                    <option value="">או בחר תיבת טקסט קיימת</option>
                                    {this.props.options.filter(val => val.label.lastIndexOf("_") === -1)
                                        .filter(val => val.label.lastIndexOf("+") === -1)
                                        .filter(val => val.label.lastIndexOf("@") === -1)
                                        .filter(val => val.label.lastIndexOf("<") === -1)
                                        .map((option, index) => (
                                            <option key={index} value={option.label}>{option.label}</option>
                                        ))}
                                </select>
                            </div>

                            {
                                ((FREELANCERS_FIELDS.includes(this.props.binddynamicSelect)
                                    && !FREELANCER_AND_ORG_FIELDS.includes(this.props.binddynamicSelect)
                                    && !this.props.organizationToggledChecked) || this.props.freelancerToggleChecked) && (
                                    <div className="form-group">
                                        <select className="form-control form-control-user" value={this.props.selectedCategory} onChange={this.props.onCategoryChange}>
                                            <option value="">בחר יועץ</option>
                                            {this.props.categories.map((name, index) => (
                                                <option key={index} value={name}>{name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            }

                            {
                                ((ORGANIZATIONS_FIELDS.includes(this.props.binddynamicSelect)
                                    && !FREELANCER_AND_ORG_FIELDS.includes(this.props.binddynamicSelect)
                                    && !this.props.freelancerToggleChecked) || this.props.organizationToggledChecked)
                                && (
                                    <div className="form-group">
                                        <select className="form-control form-control-user" value={this.props.selectedOrganizationType} onChange={this.props.onOrganizationTypeChange}>
                                            <option value="">בחר מחלקה</option>
                                            {ORGANIZATION_TYPES.map((name, index) => (
                                                <option key={index} value={name}>{name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            }

                            {
                                this.props.externalOrgToggledChecked && (
                                    <div className="form-group">
                                        <select className="form-control form-control-user" value={this.props.selectedExternalOrg} onChange={this.props.onExternalOrgChange}>
                                            <option value="">בחר גורם חיצוני</option>
                                            {EXTERNAL_ORGS.map((name, index) => (
                                                <option key={index} value={name}>{name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            }

                            {
                                this.props.externalApprovalToggledChecked && (
                                    <div className="form-group">
                                        <select className="form-control form-control-user" value={this.props.selectedExternalApproval} onChange={this.props.onExternalApprovalChange}>
                                            <option value="">בחר גורם מאשר</option>
                                            {EXTERNAL_APPROVALS.map((name, index) => (
                                                <option key={index} value={name}>{name}</option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            }

                            <div style={{ marginBottom: 10 }}>
                                <label>
                                    <input type='checkbox' style={{ marginLeft: 5 }} checked={this.props.freelancerToggleChecked} onChange={this.props.freelancerToggledCheckChanged} />
                                    שדה זה שייך ליועץ?
                                </label>
                            </div>

                            <div style={{ marginBottom: 10 }}>
                                <label>
                                    <input type='checkbox' style={{ marginLeft: 5 }} checked={this.props.organizationToggledChecked} onChange={this.props.organizationToggledCheckChanged} />
                                    שדה זה שייך למחלקה?
                                </label>
                            </div>

                            <div style={{ marginBottom: 10 }}>
                                <label>
                                    <input type='checkbox' style={{ marginLeft: 5 }} checked={this.props.externalOrgToggledChecked} onChange={this.props.externalOrgToggledCheckChanged} />
                                    שדה זה שייך לגורם חיצוני?
                                </label>
                            </div>

                            <div style={{ marginBottom: 10 }}>
                                <label>
                                    <input type='checkbox' style={{ marginLeft: 5 }} checked={this.props.externalApprovalToggledChecked} onChange={this.props.externalApprovalToggledCheckChanged} />
                                    שדה זה שייך לגורם מאשר?
                                </label>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >


                                <div className="text-center">
                                    <input
                                        type="button"
                                        className="btn blue-button btn-document-header"
                                        style={{ direction: 'rtl', color: '#fff', borderColor: Colors.PRIMARY_BLUE, backgroundColor: Colors.PRIMARY_BLUE, fontSize: 17, marginRight: 0 }}
                                        value="הוספה"
                                        onClick={this.props.closeEditTextModalPSPDFKIT}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default AddTextModal;

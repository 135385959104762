import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import { ENV } from "../config";
import { HttpRequest, HttpRequestTypes } from "../config/GeneralMethods";
import { APP_OPTIONS, Colors, USERS_ROLES, validateEmail } from "../constants";
import Switch from "react-switch";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import ESIGN_LOGO from "../assets/images/logo.png";
import TOFES4_LOGO from "../assets/images/tofes4_new.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
  },
};

const steps = [
  {
    element: ".selector1",
    intro:
      "ברוכים הבאים למערכת החתימות הדיגלטליות של בלירוקרטיה - כלים חכמים לניהול הבניה",
    position: "right",
    tooltipClass: "myTooltipClass",
    highlightClass: "myHighlightClass",
  },
  {
    element: ".template-mode-container",
    intro:
      "כדי להתחיל לבנות תבניות יש להדליק את הכפתור ולוודא שהרקע שלו הינו ירוק",
  },
];

export default class DocumentHeader extends Component {
  state = {
    isVisible: false,
    isSignature: true,
    emails: [""],
    copiedSuccess: false,
    successEmailMessage: "",
    errorEmailMessage: "",
    isEmailSending: false,
    emailSelectedChooise: "1",
    contactLists: [],
    checked: false,
    isTourEnabled: false,
    sendingEmailText: "שולח מייל",
    sendingEmailTextID: 1,
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  componentDidMount() {
    this.loadContacts();

    setTimeout(() => {
      this.showGuideTourIfNOTPreviwed();
    }, 1000);
  }

  showGuideTourIfNOTPreviwed = () => {
    const templateContainer = document.getElementById("templateContainer");

    if (templateContainer) {
      let tourFinished = localStorage.getItem("tourFinished");

      if (!tourFinished) {
        this.setState({ isTourEnabled: true });
        this.changeButtonsText();
      }
    }
  };

  changeButtonsText = () => {
    let prev = document.getElementsByClassName("introjs-prevbutton");
    let next = document.getElementsByClassName("introjs-nextbutton");

    if (prev && prev.length > 0) {
      for (let i = 0; i < prev.length; i++) {
        prev[i].textContent = "הקודם";
      }
    }

    if (next && next.length > 0) {
      for (let i = 0; i < next.length; i++) {
        next[i].textContent = "הבא";
      }
    }
  };

  setGuideTourPreviwed = () => {
    localStorage.setItem("tourFinished", "true");
    this.setState({ isTourEnabled: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userInfo !== this.props.userInfo) {
      this.loadContacts();
    }
  }

  sendForSignature = () => {
    this.setState({ isSignature: true });
    this.openModal();
  };

  sendToEmail = () => {
    this.setState({ isSignature: false });
    this.openModal();
  };

  openModal = () => {
    //Save PDF
    this.props.savePDF(false, false, false);
    this.setState({ isVisible: true });
  };

  closeModal = () => {
    this.setState({
      isVisible: false,
      emails: [""],
      errorEmailMessage: "",
      successEmailMessage: "",
    });
  };

  changeEmailContactList = (val) => {
    if (val === "-1") {
      this.setState({ emails: [""] });
      return;
    }

    let index = this.getIndexOfEmailList(val);
    let { contactLists } = this.state;

    if (index !== -1) {
      let emails = contactLists[index].emails;
      emails = emails.filter((val, ind) => val.length > 0);
      this.setState({ emails });
    }
  };

  getIndexOfEmailList = (id) => {
    let { contactLists } = this.state;

    if (!contactLists || contactLists.length === 0) {
      return -1;
    }

    for (let i = 0; i < contactLists.length; i++) {
      if (contactLists[i].id === id) {
        return i;
      }
    }

    return -1;
  };

  loadContacts = async () => {
    try {
      let contactLists = [];
      let { userInfo, token } = this.props;
      if (!userInfo) return;

      let url = `${ENV.API_URL}/${ENV.CONTACT_LIST_URL}`;
      let res = await HttpRequest(url, null, HttpRequestTypes.GET, token);

      contactLists.push({
        id: "-1",
        title: "בחר רשימה",
        emails: [""],
      });

      if (res && res.success) {
        let data = res.data || [];

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            contactLists.push({
              id: data[i]._id,
              title: data[i].title,
              userId: data[i].userId,
              emails: data[i].emails?.split(","),
            });
          }
        }
      }

      this.setState({ contactLists, isLoading: false });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  submit = async () => {
    this.setState({
      errorEmailMessage: "",
      successEmailMessage: "",
      isEmailSending: true,
    });

    this.intervalID = setInterval(() => {
      let { sendingEmailTextID } = this.state;
      let dotsCount = sendingEmailTextID === 4 ? 0 : sendingEmailTextID;
      sendingEmailTextID =
        sendingEmailTextID === 4 ? 1 : sendingEmailTextID + 1;

      let sendingEmailText = "שולח מייל ";

      for (let i = 0; i < dotsCount; i++) {
        sendingEmailText += ".";
      }

      this.setState({ sendingEmailText, sendingEmailTextID });
    }, 300);

    let { emails } = this.state;
    let fixedEmails = [];

    for (let i = 0; i < emails.length; i++) {
      if (emails[i].trim().length > 0) {
        fixedEmails.push(emails[i].trim());
      }
    }

    if (fixedEmails.length === 0) {
      this.setState({ errorEmailMessage: 'חובה להזין דוא"ל' });
    } else {
      let isAtLeastOneEmailIsNotValid = false;

      for (let i = 0; i < fixedEmails.length; i++) {
        if (!validateEmail(fixedEmails[i])) {
          isAtLeastOneEmailIsNotValid = true;
          this.setState({
            errorEmailMessage: `תיבת הטקסט מספר ${i + 1} מכילה דוא"ל לא חוקי`,
          });
          break;
        }
      }

      if (!isAtLeastOneEmailIsNotValid) {
        let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/send-pdf`;

        let params = {
          emails: fixedEmails,
          pdfUrl: this.props.link,
        };

        let token = this.props.userInfoObj.token;

        let res = await HttpRequest(url, params, HttpRequestTypes.POST, token);
        if (res) {
          this.setState({ successEmailMessage: "המסמך שותף בהצלחה" });
        } else {
          this.setState({ errorEmailMessage: "אירעה שגיאה" });
        }
      }
    }

    this.setState({ isEmailSending: false });
    clearInterval(this.intervalID);

    setTimeout(() => {
      this.closeModal();
    }, 1600);
  };

  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.props.link);
      this.props.showClipboardCopyMessage();
    } catch (e) {
      console.error(e);
    }
  };

  updateEmailContent = (ind, val) => {
    let { emails } = this.state;

    if (ind < emails.length) {
      emails[ind] = val;
      this.setState({ emails });
    }
  };

  addEmailTextBox = () => {
    let { emails } = this.state;
    emails.push("");
    this.setState({ emails });
  };

  removeEmailTextBox = (ind) => {
    let { emails } = this.state;

    if (ind < emails.length) {
      emails.splice(ind, 1);
      this.setState({ emails });
    }
  };

  renderEmailContent = () => {
    return (
      <Fragment>
        <div style={{ marginBottom: 20 }}>
          <div>
            <input
              id="manuallEmail"
              type="radio"
              name="emailOption"
              value="1"
              checked={this.state.emailSelectedChooise === "1"}
              onChange={(e) =>
                this.setState({ emailSelectedChooise: e.target.value })
              }
            />
            <label htmlFor="manuallEmail" style={{ marginRight: 5 }}>
              הזנה ידנית
            </label>
          </div>
          <div>
            <input
              id="contactsEmail"
              type="radio"
              name="emailOption"
              value="2"
              checked={this.state.emailSelectedChooise === "2"}
              onChange={(e) =>
                this.setState({ emailSelectedChooise: e.target.value })
              }
            />
            <label htmlFor="contactsEmail" style={{ marginRight: 5 }}>
              בחירה מרשימת תפוצה
            </label>
          </div>
        </div>

        {this.state.emailSelectedChooise === "1" ? null : (
          <div style={{ marginBottom: 20 }}>
            <label>בחר רשימה: </label>
            <select
              className="form-control"
              onChange={(e) => this.changeEmailContactList(e.target.value)}
            >
              {this.state.contactLists.map((val, ind) => (
                <option key={ind} value={val.id}>
                  {val.title}
                </option>
              ))}
            </select>
          </div>
        )}

        <label style={{ color: "black", marginLeft: 10 }}>דוא"ל:</label>

        <div className="form-group">
          {this.state.emails.map((val, ind) => (
            <div
              key={ind}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <input
                key={ind}
                type="text"
                name="email"
                className="form-control form-control-user"
                style={{ textAlign: "right" }}
                id={"email_in_" + ind}
                aria-describedby="emailHelp"
                placeholder="דואר אלקטרוני  "
                onChange={(e) => this.updateEmailContent(ind, e.target.value)}
                value={val}
                disabled={this.state.emailSelectedChooise === "2"}
              />

              {this.state.emailSelectedChooise === "1" ? (
                ind === this.state.emails.length - 1 ? (
                  <div
                    style={{
                      marginRight: 8,
                      backgroundColor: Colors.PRIMARY_YELLOW,
                      width: 30,
                      height: 30,
                      borderRadius: 15,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={this.addEmailTextBox}
                  >
                    <i
                      className="fa fa-plus"
                      style={{
                        color: "white",
                        fontSize: 15,
                        textAlign: "center",
                        lineHeight: 2,
                      }}
                    ></i>
                  </div>
                ) : (
                  <div
                    style={{
                      marginRight: 8,
                      backgroundColor: "red",
                      width: 30,
                      height: 30,
                      borderRadius: 15,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => this.removeEmailTextBox(ind)}
                  >
                    <i
                      className="fa fa-times"
                      style={{
                        color: "white",
                        fontSize: 15,
                        textAlign: "center",
                        lineHeight: 2,
                      }}
                    ></i>
                  </div>
                )
              ) : null}
            </div>
          ))}
        </div>

        <div className="text-center w-50 btn-send-email">
          <input
            type="button"
            className="btn btn-user btn-custom btn-block"
            style={{ backgroundColor: Colors.PRIMARY_YELLOW }}
            value={
              this.state.isEmailSending ? this.state.sendingEmailText : "שליחה"
            }
            onClick={() => (this.state.isEmailSending ? {} : this.submit())}
          />
        </div>

        <div className="mt-3 text-right mr-2">
          <span className="text-success">{this.state.successEmailMessage}</span>
        </div>
        <div className="mt-3 text-right mr-2">
          <span className="text-danger">{this.state.errorEmailMessage}</span>
        </div>
      </Fragment>
    );
  };

  renderSignatureContent = () => {
    return (
      <Fragment>
        <label style={{ color: "black", marginLeft: 10 }}>קישור:</label>

        <div className="cpyText">
          <label id="textToCopy" style={{ width: 400 }}>
            {this.props.link}
          </label>
        </div>
        <div style={{ marginTop: 10, textAlign: "center" }}>
          <button
            type="button"
            onClick={this.copyToClipboard}
            className="btn blue-button btn-document-header"
          >
            העתקת קישור
            <i className="fa fa-clipboard" style={{ marginRight: 10 }}></i>
          </button>
        </div>
      </Fragment>
    );
  };

  renderDownloadFileSection = () => {
    return this.getIsTemplateAdmin() ? null : (
      <div className="btn-actions-document-header-container download-file-class">
        <button
          className="btn btn-actions-document-header"
          onClick={() => this.props.downloadPDF()}
        >
          <label>הורד קובץ</label>
          <img
            src={require("../assets/images/001-down-arrow.png")}
            alt=""
            className="document-images"
          />
        </button>
      </div>
    );
  };

  renderSendForSignatureSection = () => {
    let allowedRoles = [USERS_ROLES.ESIGN_USER];

    return this.props.userInfoObj &&
      this.props.userInfoObj.app === APP_OPTIONS.ESIGN &&
      this.props.userInfoObj.user &&
      allowedRoles.includes(this.props.userInfoObj.user.role) ? (
      <div className="btn-actions-document-header-container">
        <button
          className="btn btn-actions-document-header"
          onClick={this.sendForSignature}
        >
          <label>שלח לחתימה</label>
          <img
            src={require("../assets/images/002-send.png")}
            alt=""
            className="document-images"
          />
        </button>
      </div>
    ) : null;
  };

  renderSendInEmailSection = () => {
    let allowedRoles = [USERS_ROLES.ESIGN_USER];

    return this.props.userInfoObj &&
      this.props.userInfoObj.app === APP_OPTIONS.ESIGN &&
      this.props.userInfoObj.user &&
      allowedRoles.includes(this.props.userInfoObj.user.role) ? (
      <div className="btn-actions-document-header-container">
        <button
          className="btn btn-actions-document-header"
          onClick={this.sendToEmail}
        >
          <label>שליחה במייל</label>
          <img
            src={require("../assets/images/003-email.png")}
            alt=""
            className="document-images"
          />
        </button>
      </div>
    ) : null;
  };

  renderTemplateToggleSection = () => {
    return (
      <div
        className="btn-actions-document-header-container template-mode-container"
        id="templateContainer"
      >
        <div className="btn-actions-document-header">
          <Switch
            onChange={this.handleChange}
            checked={this.state.checked}
            height={18}
            width={40}
          />
          <label style={{ direction: "rtl", marginLeft: 6 }}>
            מצב תבנית פעיל?
          </label>
        </div>
      </div>
    );
  };

  stepChanged = (newStepIndex, newElement) => {
    if (newStepIndex === 1) {
      //Toggle the Switch button
      setTimeout(() => {
        this.setState({ checked: true });

        setTimeout(() => {
          this.setState({ checked: false });
        }, 1000);
      }, 1000);
    }
  };

  getIsUserIsFreelancer = () => {
    return (
      this.props.userInfoObj &&
      this.props.userInfoObj.app === APP_OPTIONS.TOFES4 &&
      this.props.userInfoObj.yzm &&
      this.props.userInfoObj.freelancer
    );
  };

  getIsCanSignAndSave = () => {
    return this.getIsEsignUser() && !this.getIsTemplateAdmin();
  };

  getIsCanSave = () => {
    return this.getIsEsignUser() || this.getIsTemplateAdmin();
  };

  getIsEsignUser = () => {
    return (
      this.props.userInfoObj && this.props.userInfoObj.app === APP_OPTIONS.ESIGN
    );
  };

  getIsTemplateAdmin = () => {
    return this.props.userInfoObj && this.props.userInfoObj.tmc === true;
  };

  getFreelancerName = () => {
    let name = "";

    if (this.props.userInfoObj && this.props.userInfoObj.freelancer) {
      name =
        this.props.userInfoObj.freelancer.firstName +
        " " +
        this.props.userInfoObj.freelancer.lastName;
    }

    return name;
  };

  getIsExternalApprovalUser = () => {
    return (
      this.props.userInfoObj &&
      this.props.userInfoObj.app === APP_OPTIONS.TOFES4 &&
      this.props.userInfoObj.isExternalApproval
    );
  };

  getExternalApprovalUserTitle = () => {
    try {
      let { email, project, category } = this.props.userInfoObj;
      let { externalApprovals } = project;

      let user = externalApprovals.find(
        (item) =>
          item.email === email &&
          item.category &&
          item.category.includes(category)
      );

      if (user) {
        let userFullName = `${user.firstName} ${user.lastName}`;
        return `שלום רב, ${userFullName}\nהתקבל מסמך לטיפולך\n`;
      }

      return "חתימת מסמך";
    } catch (e) {
      return "חתימת מסמך";
    }
  };

  render() {
    let { isLoggedIn, savePDF } = this.props;

    return (
      <div className="document-header">
        {/* <div>
          {this.getIsCanSave() && (
            <button
              className="btn blue-button btn-document-header"
              style={{
                direction: "rtl",
                color: Colors.PRIMARY_BLUE,
                borderColor: Colors.PRIMARY_BLUE,
                order: 2,
              }}
              onClick={() => savePDF(false)}
            >
              שמירה
            </button>
          )}

          {this.getIsCanSignAndSave() && (
            <button
              className="btn blue-button btn-document-header"
              style={{
                direction: "rtl",
                color: "#fff",
                borderColor: Colors.PRIMARY_BLUE,
                backgroundColor: Colors.PRIMARY_BLUE,
                order: 1,
              }}
              onClick={() => this.props.submitValues()}
            >
              חתימה ואישור
            </button>
          )}
        </div> */}

        {isLoggedIn ? (
          <div className="btn-wrappers">
            {this.renderDownloadFileSection()}
            {this.renderSendForSignatureSection()}
            {this.renderSendInEmailSection()}
            {this.renderTemplateToggleSection()}
          </div>
        ) : null}

        <div>
          {this.getIsUserIsFreelancer() ? (
            <div className="text-center">
              <label
                className="document-header-title text-center"
                style={{ direction: "rtl", whiteSpace: "break-spaces" }}
              >
                {/* {`שלום רב,\nהתקבל מסמך לטיפולך\n${this.props.userInfoObj.project?.houseInformation.address}, ${this.props.userInfoObj.project?.location.city || this.props.userInfoObj.project?.location.council}`} */}
                {`שלום רב, ${this.getFreelancerName()}\nהתקבל מסמך לטיפולך\n`}
              </label>

              <div className="d-flex justify-content-between align-items-center header-address-user">
                <label className="document-header-title ">
                  {this.props.userInfoObj.ownerInformation?.firstName +
                    " " +
                    this.props.userInfoObj.ownerInformation?.lastName}
                </label>

                <label className="document-header-title ">
                  {`${
                    this.props.userInfoObj.project?.houseInformation.address
                  }, ${
                    this.props.userInfoObj.project?.location.city ||
                    this.props.userInfoObj.project?.location.council
                  }`}
                </label>
              </div>
            </div>
          ) : this.getIsExternalApprovalUser() ? (
            <div className="text-center">
              <label
                className="document-header-title text-center"
                style={{ direction: "rtl", whiteSpace: "break-spaces" }}
              >
                {this.getExternalApprovalUserTitle()}
              </label>

              <div className="d-flex justify-content-between align-items-center header-address-user">
                <label className="document-header-title ">
                  {this.props.userInfoObj.ownerInformation?.firstName +
                    " " +
                    this.props.userInfoObj.ownerInformation?.lastName}
                </label>

                <label className="document-header-title ">
                  {`${
                    this.props.userInfoObj.project?.houseInformation.address
                  }, ${
                    this.props.userInfoObj.project?.location.city ||
                    this.props.userInfoObj.project?.location.council
                  }`}
                </label>
              </div>
            </div>
          ) : (
            <label
              className="document-header-title text-right"
              style={{ direction: "rtl" }}
            >
              {this.getIsTemplateAdmin() ? "בניית תבניות" : "חתימת מסמך"}
            </label>
          )}
        </div>

        <div>
          {this.props.getIsEsignUser() ? (
            <img className="header-logo" src={ESIGN_LOGO} alt="" />
          ) : (
            <img className="header-logo" src={TOFES4_LOGO} alt="" />
          )}
        </div>

        <Steps
          enabled={this.state.isTourEnabled}
          steps={steps}
          initialStep={0}
          onExit={this.setGuideTourPreviwed}
          onAfterChange={(newStepIndex, newElement) =>
            this.stepChanged(newStepIndex, newElement)
          }
        />

        <Modal
          ariaHideApp={false}
          isOpen={this.state.isVisible}
          style={customStyles}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div></div>
              <div>
                <label className="labelModal">
                  {this.state.isSignature
                    ? "העתקת קישור לשליחה לחתימה"
                    : "שליחת טופס לחתימה באמצעות המייל"}
                </label>
              </div>
              <div>
                <i
                  className="fa fa-times pointer"
                  onClick={this.closeModal}
                ></i>
              </div>
            </div>

            <div
              style={{ direction: "rtl", marginTop: 20, textAlign: "right" }}
            >
              {this.state.isSignature
                ? this.renderSignatureContent()
                : this.renderEmailContent()}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}


import React, { Component } from 'react';
import Modal from 'react-modal';
// import { Audio } from  'react-loader-spinner'

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import ReactLoading from 'react-loading';


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      minWidth                 : 500
    }
};

export default class LoadingModal extends Component{
    
    getColor = () => {
        if (this.props.isSavingFinished) {
            if (this.props.savingResultSuccess) {
                return "green";
            } else {
                return "red";
            }
        } else {
            return "black";
        }
    }
    
    render(){
        return (
            <Modal
                ariaHideApp={false}
                isOpen={this.props.isVisible}
                style={customStyles}>

                <div>
                    <div style={{direction: 'rtl', marginTop: 20, textAlign: 'center'}}>
                        <div>

                            {
                                this.props.getIsEsignUser() ? 
                                <img alt="" src={require('../../assets/images/logo.png')}
                                style={{height: 90}}/>
                                :
                                <img alt="" src={require('../../assets/images/tofes4_new.png')}
                                style={{height: 90}}/>
                            }

                            
                        </div>
                        <div>
                            <ReactLoading type="balls" color="black" height={50} width={70} />
                        </div>
                        <div>
                            <label style={{marginTop: 10, fontSize: 17, color: this.getColor()}}>
                                {this.props.isSavingFinished ? this.props.text : " נא להמתין ..."}
                            </label>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

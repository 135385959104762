export const CONST_TEXT_FIELDS = [
    "שם פרטי של היזם/בעל ההיתר",
    "שם משפחה של היזם/בעל ההיתר",
    "שם מלא של היזם/בעל ההיתר",
    "דוא\"ל של היזם/בעל ההיתר",
    "מס׳ טלפון של היזם/בעל ההיתר",
    "כתובת מגורים של היזם/בעל ההיתר",
    "תאריך חתימה של היזם/בעל ההיתר",
    "ת\"ז של היזם/בעל ההיתר",
    "שם פרטי של היועץ",
    "שם משפחה של היועץ",
    "שם מלא של היועץ",
    "דוא\"ל של היועץ",
    "מס׳ טלפון של היועץ",
    "כתובת מגורים של היועץ",
    "ת״ז של היועץ",
    "מס׳ רשיון של היועץ",
    "תאריך חתימה של היועץ",
    "תאריך מינוי של היועץ",
    "תאריך ביקורת בנכס",
    "גוש",
    "חלקה",
    "מס׳ מגרש",
    "כתובת המבנה",
    "יישוב",
    "סוג מבנה",
    "מספר היתר",
    "תאריך היתר",
    "תיאור הבקשה",
    "מס׳ בקשה",
    "מס׳ תיק בניין",
    "שכונה/מתחם",
    "שם מלא של המפקח",
    "תאריך מילוי המסמך",
    "מחוז",
    "רשות"
];


export const SIGNATURE_FIELDS = [
    "חתימת היזם/בעל ההיתר",
    "חתימת יועץ",
    "חותמת יועץ",
    "חתימת מחלקה",
    "חותמת מחלקה",
    "חתימת גורם חיצוני",
    "חתימת גורם מאשר",
];

export const FREELANCERS_FIELDS = [
    "שם פרטי של היועץ",
    "שם משפחה של היועץ",
    "שם מלא של היועץ",
    "דוא\"ל של היועץ",
    "מס׳ טלפון של היועץ",
    "חתימת יועץ",
    "חותמת יועץ",
    "כתובת מגורים של היועץ",
    "ת״ז של היועץ",
    "מס׳ רשיון של היועץ",
    "תאריך חתימה של היועץ",
    "תאריך מינוי של היועץ",
    "תאריך ביקורת בנכס",
    "תאריך מילוי המסמך"
];

export const ORGANIZATIONS_SIGNATURE_FIELDS = [
    "חתימת מחלקה",
    "חותמת מחלקה",
];

export const ORGANIZATIONS_FIELDS = [
   ...ORGANIZATIONS_SIGNATURE_FIELDS,
    "שם מלא של המפקח"
]

export const EXTERNAL_ORGS_SIGNATURE_FIELDS = [
    "חתימת גורם חיצוני"
];

export const EXTERNAL_APPROVALS_SIGNATURE_FIELDS = [
    "חתימת גורם מאשר"
];

export const FREELANCERS_FIELDS_TITLES = {
    FIRST_NAME: "שם פרטי של היועץ",
    LAST_NAME: "שם משפחה של היועץ",
    FULL_NAME: "שם מלא של היועץ",
    EMAIL: "דוא\"ל של היועץ",
    PHONE: "מס׳ טלפון של היועץ",
    SIGNATURE: "חתימת יועץ",
    STAMP: "חותמת יועץ",
    ADDRESS: "כתובת מגורים של היועץ",
    ID_NUMBER: "ת״ז של היועץ",
    LICENSE_NUMBER: "מס׳ רשיון של היועץ",
    DATE_OF_SIGNATURE: "תאריך חתימה של היועץ",
    DATE_OF_START_WORKING_ON_THIS_PROJECT: "תאריך מינוי של היועץ"
}

export const FREELANCER_AND_ORG_FIELDS = [
    "תאריך ביקורת בנכס"
];
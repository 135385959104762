import React, { Component } from "react";
import PSPDFKitWeb from "pspdfkit";
import moment from "moment";
import { TEMPLATE_FIELDS_TYPES } from "../../constants";
import { ENV } from "../../config";

const STORAGE_KEY = "signatures_storage";
const ATTACHMENTS_KEY = "attachments_storage";
const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

export default class PSPDFKit extends Component {
  constructor(props, context) {
    super(props, context);
    this._instance = null;
    this._container = null;

    this.onRef = this.onRef.bind(this);
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
  }

  state = {
    textEdit: "מצב עריכה", //"Edit Mode",
    textCreate: "מצב עיצוב", //"Design Mode",
    flattenAnnotations: false,
    createAnnotation: true,
    dynamicSelectsignautrechild: "",
    isEditableAnnotation: false,
    isMobile: false,
  };

  onRef(container) {
    this._container = container;
  }

  componentDidMount() {
    this.load(this.props);

    this.updateMobileMode();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.updateMobileMode();
  };
  getWindowsWidth = () => {
    return window.innerWidth;
  };
  updateMobileMode = () => {
    this.setState({ isMobile: this.getWindowsWidth() <= 600 });
  };

  async load(props) {
    setTimeout(() => this.loadInstanceAndElements(props), 300);
  }

  removeDownloadFromToolBarIcons = () => {
    this._instance.setToolbarItems(
      this._instance.toolbarItems.filter((item) => item.type !== "export-pdf")
    );
  };

  getCenterWidthAndHeightForCurrentPage = () => {
    try {
      let pageInfo = this._instance.pageInfoForIndex(
        this._instance.viewState.currentPageIndex
      );

      let centerX = pageInfo.width / 2;
      let centerY = pageInfo.height / 2;

      return {
        x: centerX > 50 ? centerX - 30 : centerX,
        y: centerY > 50 ? centerY - 30 : centerY,
      };
    } catch (e) {
      console.log(e);

      return {
        x: 30,
        y: 20,
      };
    }
  };

  addTextAnnotation = (title) => {
    const newTextField = new PSPDFKitWeb.Annotations.TextAnnotation({
      text: title,
      pageIndex: this._instance.viewState.currentPageIndex,
      id: PSPDFKitWeb.generateInstantId(),
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 20,
        height: 20,
      }),
    });

    this._instance.create([newTextField]);
  };

  loadInstanceAndElements = async (props) => {
    const toolbarItems = PSPDFKitWeb.defaultToolbarItems.filter((item) => {
      return /\b(pager|text|stamp|image|export-pdf|zoom-in|zoom-out)\b/.test(
        item.type
      );
    });

    //add custom icons to toolbar
    toolbarItems.push({
      type: "custom",
      id: "toolbarCrossIcon",
      title: "❌",
      onPress: () => {
        this.addTextAnnotation("❌");
      },
    });

    toolbarItems.push({
      type: "custom",
      id: "toolbarCheckIcon",
      title: "✅",
      onPress: () => {
        this.addTextAnnotation("✅");
      },
    });

    //signature

    toolbarItems.push({
      type: "spacer",
    });

    // toolbarItems.push({
    //   type: "custom",
    //   id: "my-custom-buttonedit",
    //   title: "Export",
    //   strokeColor: "red",
    //   onPress: (event) => {
    //     this.downLoadPDF();
    //   },
    // });

    toolbarItems.push({
      type: "custom",
      id: "my-custom-buttoncreate",
      title: this.state.textCreate,
      onPress: (event) => {
        this.formDesignmode(true);
      },
    });
    // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
    toolbarItems.push({
      type: "custom",
      id: "my-custom-buttonedit",
      title: this.state.textEdit,
      onPress: (event) => {
        this.formDesignmode(false);
      },
    });

    toolbarItems.push({
      type: "search",
    });

    const customFonts = ["Assistant-Regular.ttf"].map(
      (font) => new PSPDFKitWeb.Font({ name: font, callback: this.fetcher })
    );

    let documentUrl = this.getDocumentUrlFromServer(props.documentUrl);

    const configuration = {
      customFonts,
      toolbarItems: toolbarItems,
      document: documentUrl,
      container: this._container,
      baseUrl: props.baseUrl,
      isAPStreamRendered: () => false,
      //licenseKey: ENV.PSPDFKIT_LICENSE_KEY
    };

    this._instance = await PSPDFKitWeb.load(configuration);

    await this._instance?.exportPDF();
    this.createSignatureEventListnersupport1();
    this.customIcons();
    this.props.instanceInitialized(this._instance);
  };

  getDocumentUrlFromServer = (url) => {
    try {
      return `${ENV.API_URL}/api/files/download-file?url=${url}`;
    } catch (e) {
      console.log(e);
      return url;
    }
  };

  fetcher = async (fontFileName) => {
    try {
      let url = baseUrl + fontFileName;
      let res = await fetch(url);

      if (res.status === 200) return await res.blob();
      throw new Error(`font '${fontFileName}' was NOT found`);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  //used by another process
  async downLoadPDF() {
    await this._instance.exportPDF().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/pdf" });

      let fileName = "";

      try {
        fileName = this.props.userInfoObj?.document?.title;

        if (!fileName) {
          fileName = "document.pdf";
        }
      } catch (e) {
        console.log(e);
      }

      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = objectUrl;
      a.style = "display: none";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    });
  }

  getFormFieldsValues() {
    this._instance.getFormFields().then((formFields) => {
      // Filter form fields by type
      formFields.filter(
        (formField) => formField instanceof PSPDFKitWeb.FormFields.TextFormField
      );
    });
  }

  async getWidgetsByFormFieldName(formFieldNames) {
    // Setting this variable you can specify what form fields get targeted
    const acceptedFormFieldNames = formFieldNames;
    const annotationsByPages = await Promise.all(
      Array.from({ length: this._instance.totalPageCount }).map((_, index) =>
        this._instance.getAnnotations(index)
      )
    );

    return annotationsByPages.reduce((acc, pagesAnnotations) => {
      const pageWidgetsFound = pagesAnnotations.filter(
        (annotation) =>
          formFieldNames.includes(annotation.formFieldName) &&
          acceptedFormFieldNames.includes(annotation.formFieldName)
      );

      return [...acc, ...pageWidgetsFound.toArray()];
    }, []);
  }

  async assignSignature(signature) {
    let isImageSignature = false;

    if (signature && signature.toJS() && signature.toJS().imageAttachmentId) {
      isImageSignature = true;
    }

    let label = localStorage.getItem("dynamicsignatureField");
    let dynamicSelectsignautrechild =
      typeof label === typeof "" ? label : JSON.parse(label);

    let originalLabel = localStorage.getItem(
      "dynamicsignatureField_originalLabel"
    );

    let isFilledString = localStorage.getItem("dynamicsignatureField_isFilled");
    let isFilled =
      isFilledString && isFilledString.toLowerCase() === "true" ? true : false;

    if (dynamicSelectsignautrechild === null) {
      dynamicSelectsignautrechild = "CustomSignature";
    }

    //const formFields = await this._instance.getFormFields();

    // const signatureFormFields = formFields.filter(
    //   (formField) => formField instanceof PSPDFKitWeb.FormFields.SignatureFormField
    // );

    let all = await this.getAnnotationsArrayList();
    let currentList = all.filter((val) => val.formFieldName === label);
    let current = currentList.length > 0 ? currentList[0] : null;

    let { optionsSignature } = this.props;
    let fieldToCheck =
      originalLabel && originalLabel.length > 0
        ? originalLabel
        : dynamicSelectsignautrechild;
    let signaturesFromTemplate = optionsSignature.filter(
      (val) => val.originalLabel === fieldToCheck
    );

    const signatureWidgets = await this.getWidgetsByFormFieldName(
      signaturesFromTemplate.map((val) => val.originalLabel)
    );

    const originPointTranslation = {
      x: -signature.boundingBox.left,
      y: -signature.boundingBox.top,
    };

    const newSignatures = signatureWidgets.map((widget) => {
      let newSignautreId = PSPDFKitWeb.generateInstantId();

      const widthRatio =
        widget.boundingBox.width /
        (signature.boundingBox.width - signature.lineWidth);
      const heightRatio =
        widget.boundingBox.height /
        (signature.boundingBox.height - signature.lineWidth);
      const ratio = Math.min(widthRatio, heightRatio);

      const newSignatureSize = {
        width: isImageSignature ? 30 : signature.boundingBox.width * ratio,
        height: isImageSignature ? 30 : signature.boundingBox.height * ratio,
      };

      const newBBox = new PSPDFKitWeb.Geometry.Rect({
        ...newSignatureSize,
        left:
          widget.boundingBox.left +
          widget.boundingBox.width / 2 -
          newSignatureSize.width / 2,
        top:
          widget.boundingBox.top +
          widget.boundingBox.height / 2 -
          newSignatureSize.height / 2,
      });

      // We want the new annotation to be on the other signature widget, so we set the bb to that.
      // Since the lines need to be inside the new bb, we need to adapt them with the offset.

      const _newSignature = signature
        .set("id", newSignautreId)
        .set("boundingBox", newBBox)
        .set("pageIndex", widget.pageIndex)
        .set("customData", { isCopy: true })
        .set("lineWidth", 1)
        .set(
          "lines",
          signature.lines
            ? signature.lines.map((line) =>
                line.map((point) =>
                  point
                    .translate(originPointTranslation)
                    .scale(ratio)
                    .translate({
                      x: newBBox.left,
                      y: newBBox.top,
                    })
                )
              )
            : []
        );

      return _newSignature;
    }, []);

    //we need to update the id as well
    let templateItem = null;

    if (current) {
      templateItem = this.getTemplateItemByOriginalLabel(current.formFieldName);
    }
    let oldSignatureId = "";

    if (
      templateItem &&
      templateItem.relatedIds &&
      templateItem.relatedIds.length > 0
    ) {
      oldSignatureId = templateItem.relatedIds[0].signatureId;
    }

    //remove additional-Signature created by the process:

    this._instance.delete(signature.id);

    if (isFilled) {
      this._instance.delete(oldSignatureId);
    }

    this._instance.create(newSignatures);
    this._instance.save();

    localStorage.removeItem("dynamicsignatureField");
    localStorage.removeItem("dynamicsignatureField_isFilled");
    localStorage.removeItem("dynamicsignatureField_originalLabel");

    if (current) {
      this.props.updateTemplateSignatureItemByLabel(fieldToCheck);
    }

    this.props.toggleFillPopup(fieldToCheck);
  }

  getTemplateItemByOriginalLabel = (label) => {
    let userInfoObj = this.props.userInfoObj;
    let template = userInfoObj?.document?.template || [];

    if (!template || template.length === 0) return null;

    let items = template.filter((item) => item.label === label);
    return items.length > 0 ? items[0] : null;
  };

  async createSignatureEventListnersupport1() {
    if (!this._instance) return;

    this.formDesignmode(false);

    this._instance.addEventListener(
      "annotations.create",
      async (annotations) => {
        let checkType = annotations.toJS();

        if (checkType[0].description === "Example Image Annotation") {
          return;
        }

        const signature = annotations.find(
          (annotation) => annotation.isSignature
        );

        if (
          signature &&
          (!signature.customData || !signature.customData.isCopy)
        ) {
          this.assignSignature(signature);
        }
      }
    );

    this._instance.addEventListener(
      "annotations.delete",
      async (annotations) => {
        let checkType = annotations.toJS();

        if (checkType && checkType.length > 0) {
          this.props.removeItemFromTemplate(checkType[0].formFieldName);
        }
      }
    );

    this._instance.addEventListener(
      "viewState.change",
      (viewState, previousState) => {
        //check if the current view state is "signature"
        if (viewState.interactionMode?.toLowerCase() === "signature") {
          //DO Nothing
        }
        //Otherwise if the previous view state is "signature" the document editor was closed
        if (previousState.interactionMode?.toLowerCase() === "signature") {
          this.props.viewApproveButtonAgain();
        }
      }
    );
  }

  openSignaturePopUp() {
    this._instance.setViewState((v) =>
      v.set("interactionMode", PSPDFKitWeb.InteractionMode.SIGNATURE)
    );
  }

  async createSignatureEventListnersupport() {
    this._instance.addEventListener(
      "annotations.create",
      async (annotations) => {
        let checkType = annotations.toJS();

        if (checkType[0].formFieldName !== undefined) {
          if (checkType[0].formFieldName.indexOf("formfieldNamesig") !== -1) {
            this.setState({ createAnnotation: true });
            return;
          }
          if (
            checkType[0].formFieldName.indexOf("firstnameform") !== -1 ||
            checkType[0].formFieldName.indexOf("emptyformfieldName") !== -1 ||
            checkType[0].formFieldName.indexOf("firstname") !== -1 ||
            checkType[0].formFieldName.indexOf("lastName") !== -1 ||
            checkType[0].formFieldName.indexOf("Address") !== -1 ||
            checkType[0].formFieldName.indexOf("תאריך מילוי המסמך") !== -1
          ) {
            return;
          }
        }

        let formFieldName;
        this.setState({ createAnnotation: false });
        const allformFields = await this._instance.getFormFields();

        let dynamicSelectsignautrechild = JSON.parse(
          localStorage.getItem("signatureField")
        );
        if (
          dynamicSelectsignautrechild === undefined ||
          dynamicSelectsignautrechild === ""
        )
          return;
        const formFieldss = allformFields.toJS();
        for (let i = 0; i < formFieldss.length; i++) {
          if (formFieldss[i].name.indexOf(dynamicSelectsignautrechild) !== -1) {
            formFieldName = formFieldss[i].name;
          }
          localStorage.removeItem("signatureField");
          // First get all `FormFields` in the `Document`.
          //
          const formFields = await this._instance.getFormFields();

          const field = formFields.find(
            // eslint-disable-next-line
            (formField) =>
              formField.name === formFieldName &&
              formField instanceof PSPDFKitWeb.FormFields.SignatureFormField
          );

          // const index =  field.annotationIds.findIndex(id => id === annotations.id);
          // const option = field.options.get(index);
          // In this example, assume the widget you need is on the first page.
          //
          if (formFieldName !== undefined) {
            var allannotations = await this._instance.getAnnotations(0);

            // Find that widget.
            var widget = allannotations.find(
              (annotation) =>
                annotation instanceof
                  PSPDFKitWeb.Annotations.WidgetAnnotation &&
                annotation.formFieldName === field.name
            );

            if (widget === undefined) {
              for (let i = 0; i < 100; i++) {
                allannotations = await this._instance.getAnnotations(i);
                widget = allannotations.find(
                  (annotation) =>
                    annotation instanceof
                      PSPDFKitWeb.Annotations.WidgetAnnotation &&
                    annotation.formFieldName === field.name
                );
                if (widget !== undefined) {
                  break;
                }
              }
            }

            //var signature = annotations.first();
            const signature = annotations.find(
              (annotation) => annotation.isSignature
            );
            const offsetTranslation = new PSPDFKitWeb.Geometry.Point({
              x:
                widget.boundingBox.left -
                signature.boundingBox.left +
                (widget.boundingBox.width - signature.boundingBox.width) / 2,
              y:
                widget.boundingBox.top -
                signature.boundingBox.top +
                (widget.boundingBox.height - signature.boundingBox.height) / 2,
            });

            const newBbox = signature.boundingBox.translate(offsetTranslation);

            // We want the new annotation to be on the other signature widget, so we set the bb to that.
            // Since the lines need to be inside the new bb, we need to adapt them with the offset.
            const newSignature = signature
              .set("id", null)
              .set("boundingBox", newBbox)
              .set("isDeletable", true)
              .set("pageIndex", widget.pageIndex)
              .set(
                "lines",
                signature.lines.map((line) => {
                  return line.map((point) =>
                    point.translate(offsetTranslation)
                  );
                })
              );

            this._instance.create(newSignature);
            this._instance.delete(signature);
            await this._instance.save();
          }
        }
      }
    );
  }

  customIcons() {
    if (!this._instance) return;
    //hide search icon
    this._instance.setToolbarItems((items) =>
      items.filter((item) => item.type !== "search")
    );

    //change icons of toolbar
    this._instance.setToolbarItems((items) =>
      items.map((item) => {
        if (item.type === "text") {
          item.icon = "/text.jfif";
          item.title = "תיבת טקסט";
        }
        if (item.type === "text-highlighter") {
          item.icon = "/texthighlighter.jfif";
          item.title = "סימון טקסט";
        }
        //signature|pager|text|stamp|export-pdf|image|zoom-in|zoom-out
        if (item.type === "signature") {
          item.title = "חתימה";
        }
        if (item.type === "stamp") {
          item.title = "חותמת";
        }
        if (item.type === "image") {
          item.title = "תמונה";
        }
        if (item.type === "export" || item.type === "export-pdf") {
          item.title = "הורדת מסמך";
        }
        if (item.type === "zoom-in") {
          item.title = "הגדל תצוגה";
        }
        if (item.type === "zoom-out") {
          item.title = "הקטן תצוגה";
        }

        return item;
      })
    );
  }

  //load and unload form design mode
  formDesignmode(mode) {
    if (this._instance) {
      this._instance.setViewState((viewState) =>
        viewState.set("formDesignMode", mode)
      );
    }

    //this._instance.setViewState(v => v.set("readOnly", false));
    //this._instance.getFormFields().then(ff => this._instance.update(ff.map(f => f.set('readOnly', false))));
  }

  unload() {
    PSPDFKitWeb.unload(this._instance || this._container);
    this._instance = null;
  }

  getAllFormFieldsFromInstance = async () => {
    return await this._instance.getFormFields();
  };

  async getFormFields(fieldName, fieldValue) {
    if (fieldValue === "") return;
    //const myArray = fieldName.split("-");
    const allformFields = await this._instance.getFormFields();

    const formFields = allformFields.toJS();

    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name.indexOf(fieldValue) !== -1) {
        this.setFormFields(formFields[i].name, fieldName);
      }
    }
  }

  setFormFields(fieldName, value) {
    const formFieldValue = new PSPDFKitWeb.FormFieldValue({
      name: fieldName,
      value: value,
    });
    this._instance.update(formFieldValue);
  }

  async flattenAnnotations() {
    await this.formDesignmode(false);
    await this._instance.applyOperations([
      {
        type: "flattenAnnotations",
      },
    ]);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    // We only want to reload the document when the documentUrl prop changes.
    if (nextProps.documentUrl !== prevProps.documentUrl) {
      this.unload();
      this.load(nextProps);
    }
  }

  componentWillUnmount() {
    this.unload();
    window.removeEventListener("resize", this.handleResize);
  }

  //related textbox control
  relatedTextBox() {
    //const formfieldName = this.createFormFieldName();
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: "formfieldName",
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: 100,
        top: 75,
        width: 200,
        height: 30,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: "formfieldName",
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: "relatedTextBox",
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
  }

  //Empty textbox control
  exmptyTextBox(fieldName, label, flattenannotation) {
    this.setState({ flattenannotation: flattenannotation });
    const formfieldName = this.createFormFieldName();
    const uniqueFieldName = fieldName + "-emptyformfieldName-" + formfieldName;
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: uniqueFieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 75,
        height: 16,
      }),
      blendMode: "multiply",
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: uniqueFieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: "",
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
    this._instance.update(widget.set("horizontalAlign", "right"));

    //Modify Field Name
    setTimeout(() => {
      this.props.modifyTextFieldWithValues(fieldName, uniqueFieldName);
    }, 600);
  }

  //First Name textbox control
  firstNameTextBox() {
    let { userInfo } = this.props;
    if (!userInfo) return;
    let { firstName } = userInfo.userSettings;

    const formfieldName = this.createFormFieldName();
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: "firstName_" + formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 200,
        height: 30,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: "firstName_" + formfieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: firstName,
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
    this._instance.update(widget.set("horizontalAlign", "right"));
  }

  //Last Name textbox control
  lastNameTextBox() {
    let { userInfo } = this.props;
    if (!userInfo) return;
    let { lastName } = userInfo.userSettings;

    const formfieldName = this.createFormFieldName();
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: "lastName" + formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 200,
        height: 30,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: "lastName" + formfieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: lastName,
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
    this._instance.update(widget.set("horizontalAlign", "right"));
  }

  //address textbox control
  addressTextBox() {
    let { userInfo } = this.props;
    if (!userInfo) return;
    let { address } = userInfo.userSettings;

    const formfieldName = this.createFormFieldName();
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: "address" + formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 200,
        height: 30,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: "address" + formfieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: address,
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
    this._instance.update(widget.set("horizontalAlign", "right"));
  }

  //Signature control
  signatureTextBox(dynamicValue, flattenannotation) {
    if (dynamicValue === "") {
      dynamicValue = "CustomSignature";
    }

    this.setState({ flattenannotation });
    //const formfieldName = this.createFormFieldName();
    // Create a new text form field.

    const uniqueId = PSPDFKitWeb.generateInstantId();
    const formUniqueId = PSPDFKitWeb.generateInstantId();

    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: uniqueId,
      name: dynamicValue,
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: dynamicValue,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 200,
        height: 30,
      }),
    });

    const formField = new PSPDFKitWeb.FormFields.SignatureFormField({
      name: dynamicValue,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: "",
      label: uniqueId,
      id: formUniqueId,
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);

    //Add Signature details
    setTimeout(() => {
      this.props.modifySignatureFieldWithValues(
        dynamicValue,
        uniqueId,
        formUniqueId
      );
    }, 600);
  }

  //current date control
  currentDateTextBox() {
    const formfieldName = this.createFormFieldName();
    let currDate = moment(new Date().getTime()).format("DD/MM/YYYY");
    // Create a new text form field.
    const widget = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: "תאריך מילוי המסמך" + formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 200,
        height: 30,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.TextFormField({
      name: "תאריך מילוי המסמך" + formfieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([widget.id]),
      value: currDate,
    });

    this._instance.create([widget, formField]);
    this.formDesignmode(true);
    this._instance.update(widget.set("horizontalAlign", "right"));
  }

  async addImageAnnotation(url) {
    const request = await fetch(url);
    const blob = await request.blob();
    const imageAttachmentId = await this._instance.createAttachment(blob);
    const annotation = new PSPDFKitWeb.Annotations.ImageAnnotation({
      pageIndex: this._instance.viewState.currentPageIndex,
      isSignature: true,
      contentType: "image/jpeg",
      imageAttachmentId,
      description: "Example Image Annotation",
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 400,
        height: 250,
      }),
    });

    this._instance.create(annotation);
  }

  //radio button control
  radiobutton() {
    const formfieldName = this.createFormFieldName();
    // Create two radio buttons and position them in the document.
    // Note that both widget annotations have the same `formFieldName` value.
    const radioWidget1 = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: this.getCenterWidthAndHeightForCurrentPage().x,
        top: this.getCenterWidthAndHeightForCurrentPage().y,
        width: 20,
        height: 20,
      }),
    });
    const radioWidget2 = new PSPDFKitWeb.Annotations.WidgetAnnotation({
      id: PSPDFKitWeb.generateInstantId(),
      pageIndex: this._instance.viewState.currentPageIndex,
      formFieldName: formfieldName,
      boundingBox: new PSPDFKitWeb.Geometry.Rect({
        left: 130,
        top: 100,
        width: 20,
        height: 20,
      }),
    });
    const formField = new PSPDFKitWeb.FormFields.RadioButtonFormField({
      name: formfieldName,
      annotationIds: new PSPDFKitWeb.Immutable.List([
        radioWidget1.id,
        radioWidget2.id,
      ]),
      options: new PSPDFKitWeb.Immutable.List([
        new PSPDFKitWeb.FormOption({
          label: "Option 1",
          value: "1",
        }),
        new PSPDFKitWeb.FormOption({
          label: "Option 2",
          value: "2",
        }),
      ]),
      //defaultValue: "1",
    });
    this._instance.create([radioWidget1, radioWidget2, formField]);
    this.formDesignmode(true);
  }

  //get the blob of PDF for saving
  async savePDFPSPDFKit(savePDFPSPDFKit = false) {
    //before save
    //go through all the text-fields and make the background white
    //await this.makeAllFormFieldsBGWhite();
    //await this.makeAllFormFieldsBGNormal();

    const arrayBuffer = await this._instance.exportPDF();
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    return blob;
  }

  makeAllFormFieldsBGWhite = async () => {
    try {
      let pagesCount = this._instance.totalPageCount;

      for (let i = 0; i < pagesCount; i++) {
        let annotations = await this._instance.getAnnotations(i);

        let annotationSize = annotations.size;
        for (let j = 0; j < annotationSize; j++) {
          let currAnnotation = annotations.get(j);

          await this._instance.update([
            currAnnotation
              .set("backgroundColor", PSPDFKitWeb.Color.WHITE)
              .set("blendMode", "multiply"),
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  makeAllFormFieldsBGNormal = async () => {
    try {
      let pagesCount = this._instance.totalPageCount;

      for (let i = 0; i < pagesCount; i++) {
        let annotations = await this._instance.getAnnotations(i);

        let annotationSize = annotations.size;
        for (let j = 0; j < annotationSize; j++) {
          let currAnnotation = annotations.get(j);

          await this._instance.update([
            currAnnotation.remove("backgroundColor"),
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  getFullAnnotationsList = async () => {
    let pagesCount = this._instance.totalPageCount;
    let annotationsArray = [];

    for (let i = 0; i < pagesCount; i++) {
      let annotations = await this._instance.getAnnotations(i);
      let annotationSize = annotations.size;

      for (let j = 0; j < annotationSize; j++) {
        let currAnnotation = annotations.get(j);
        annotationsArray.push(currAnnotation.toJS());
      }
    }

    return annotationsArray;
  };

  getAnnotationsArrayList = async () => {
    try {
      let pagesCount = this._instance.totalPageCount;
      let annotationsArray = [];
      const formFieldValues = this._instance.getFormFieldValues();

      for (let i = 0; i < pagesCount; i++) {
        let annotations = await this._instance.getAnnotations(i);
        let annotationSize = annotations.size;

        for (let j = 0; j < annotationSize; j++) {
          let currAnnotation = annotations.get(j);
          annotationsArray.push(
            this.getFormFieldObject(formFieldValues, currAnnotation)
          );
        }
      }

      return annotationsArray;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  getFormFieldObject = (formFields, annotation) => {
    let value = formFields[annotation.formFieldName];

    return {
      pageIndex: annotation.pageIndex,
      id: annotation.id,
      name: annotation.name,
      formFieldName: annotation.formFieldName,
      boundingBox: {
        left: annotation.boundingBox.left,
        top: annotation.boundingBox.top,
        width: annotation.boundingBox.width,
        height: annotation.boundingBox.height,
      },
      value,
      type:
        value === null
          ? TEMPLATE_FIELDS_TYPES.SIGNATURE
          : TEMPLATE_FIELDS_TYPES.TEXT,
    };
  };

  //Random form field name
  createFormFieldName() {
    return `form-field-${Math.random().toString(36).slice(-5)}`;
  }

  async customSignatureStore() {
    const signaturesString = localStorage.getItem(STORAGE_KEY);

    if (signaturesString) {
      const storedSignatures = JSON.parse(signaturesString);
      // Construct annotations from serialized entries and call setStoredSignatures API
      const list = PSPDFKitWeb.Immutable.List(
        storedSignatures.map(PSPDFKitWeb.Annotations.fromSerializableObject)
      );

      this._instance.setStoredSignatures(list);

      const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);

      if (attachmentsString) {
        const attachmentsArray = JSON.parse(attachmentsString);
        // from the data URLs on local storage instantiate Blob objects
        const blobs = await Promise.all(
          attachmentsArray.map(({ url }) =>
            fetch(url).then((res) => res.blob())
          )
        );

        // create an attachment for each blob
        blobs.forEach(this._instance.createAttachment);
      }
    }
    this._instance.addEventListener(
      "storedSignatures.create",
      async (annotation) => {
        const signaturesString = localStorage.getItem(STORAGE_KEY);
        const storedSignatures = signaturesString
          ? JSON.parse(signaturesString)
          : [];

        const serializedAnnotation =
          PSPDFKitWeb.Annotations.toSerializableObject(annotation);

        if (annotation.imageAttachmentId) {
          const attachment = await this._instance.getAttachment(
            annotation.imageAttachmentId
          );

          // Create data URL and add it to local storage.
          // Note: This is done only for demonstration purpose.
          // Storing potential large chunks of data using local storage is
          // considered bad practice due to the synchronous nature of that API.
          // For production applications, please consider alternatives such a
          // dedicated back-end storage or IndexedDB.
          const url = await this.fileToDataURL(attachment);
          const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
          const attachmentsArray = attachmentsString
            ? JSON.parse(attachmentsString)
            : [];

          attachmentsArray.push({ url, id: annotation.imageAttachmentId });
          localStorage.setItem(
            ATTACHMENTS_KEY,
            JSON.stringify(attachmentsArray)
          );
        }

        storedSignatures.push(serializedAnnotation);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(storedSignatures));
        // Add new annotation so that its render as part of the UI on the current session
        this._instance.setStoredSignatures((signatures) =>
          signatures.push(annotation)
        );
      }
    );

    this._instance.addEventListener("storedSignatures.delete", (annotation) => {
      const signaturesString = localStorage.getItem(STORAGE_KEY);
      const storedSignatures = signaturesString
        ? JSON.parse(signaturesString)
        : [];
      const annotations = storedSignatures.map(
        PSPDFKitWeb.Annotations.fromSerializableObject
      );
      const updatedAnnotations = annotations.filter(
        (currentAnnotation) => !currentAnnotation.equals(annotation)
      );

      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify(
          updatedAnnotations.map(PSPDFKitWeb.Annotations.toSerializableObject)
        )
      );
      // Use setStoredSignatures API so that the current UI is properly updated
      this._instance.setStoredSignatures((signatures) =>
        signatures.filter((signature) => !signature.equals(annotation))
      );

      if (annotation.imageAttachmentId) {
        // Remove attachment from array
        const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);

        if (attachmentsString) {
          let attachmentsArray = JSON.parse(attachmentsString);

          attachmentsArray = attachmentsArray.filter(
            (attachment) => attachment.id !== annotation.imageAttachmentId
          );
          localStorage.setItem(
            ATTACHMENTS_KEY,
            JSON.stringify(attachmentsArray)
          );
        }
      }
    });

    return this._instance;
  }

  fileToDataURL(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  render() {
    return <div ref={this.onRef} style={{ width: "100%", height: "100%" }} />;
  }
}

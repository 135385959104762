import React, { Component } from "react";

import "../assets/styles/spinner.css";

export default class LoadingSpinner extends Component {
  render() {
    return (
      <div className="loading-spinner">
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from 'react';

export default class DocumentMenu extends Component{

    state = {
        personalInfo: [
            {key: 'firstName', name: 'שם פרטי', src: require('../assets/images/user-black.png'), onPress: () => this.props.addConstant('firstName')},
            {key: 'lastName', name: 'שם משפחה', src: require('../assets/images/user-black.png'), onPress: () => this.props.addConstant('lastName')},
            {key: 'address', name: 'כתובת', src: require('../assets/images/004-placeholder.png'), onPress: () => this.props.addConstant('address')},
            {key: 'signatureUrl', name: 'חתימה', src: require('../assets/images/005-fountain-pen.png'), onPress: () => this.props.signatureImage()},
            {key: 'stampUrl', name: 'חותמת דיגטלית', src: require('../assets/images/007-stamp.png'), onPress: () => this.props.stampImage()},
        ],
        tools: [
            {key: 'text', name: 'תיבת טקסט', src: require('../assets/images/006-text.png'), onPress: () => this.props.addText()},
            // {key: 'image', name: 'תמונה', src: require('../assets/images/camera-black.png'), onPress: () => this.props.addImage()},
            {key: 'signature', name: 'חתימה חדשה', src: require('../assets/images/005-fountain-pen.png'), onPress: () => this.props.addSignature()},
            {key: 'currDate', name: 'תאריך נוכחי', src: require('../assets/images/calendar-black.png'), onPress: () => this.props.addDate()}
        ]
    };
    
    getIsTemplateAdmin = () => {
        return (this.props.userInfoObj && this.props.userInfoObj.tmc === true);
    }

    render(){
        return (
            <div className="document-menu-wrapper">
                
                {
                    (!this.props.isLoggedIn || this.getIsTemplateAdmin()) ? 
                    null :
                    <Fragment>
                        
                        <div className="document-title">
                            <label>פרטים אישיים</label>
                        </div>
                    
                        <ul>
                            {
                                this.state.personalInfo.map( (val, ind) => 
                                    <li key={ind}>
                                        <div className="d-flex justify-content-between align-items-center custom-menu" onClick={val.onPress}>
                                            <img className="img-menu" alt="" src={val.src} />
                                            <label>{val.name}</label>
                                        </div>
                                        
                                    </li>
                                )
                            }
                        </ul>
                    </Fragment> 
    
                }
                

                <div className="document-title">
                    <label>כלי עריכה</label>
                </div>

                <ul>
                    {
                        this.state.tools.map( (val, ind) => 
                            <li key={ind}>
                                <div className="d-flex justify-content-between align-items-center custom-menu" onClick={val.onPress}>
                                    <img className="img-menu" alt="" src={val.src} />
                                    <label>{val.name}</label>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }
}
export const ENV = {
    API_URL: "https://api-esign.blirokratia.com",
    //API_URL: "http://localhost:5001",
    CLIENT_URL: 'http://esign.blirokratia.com',
    USERS_API_URL: "api/user",
    DOCUMENTS_API_URL: "api/document",
    UPLOADS_API_URL: "api/upload",
    FILE_DOWNLOAD : 'api/files',
    CONTACT_LIST_URL: "api/contact-list",
    COMPANY_LIST_URL: "api/company-list",
    NOTIFICATIONS_URL: "api/notifications",
    TERMS_URL: "https://blirokratiabucket.s3.eu-central-1.amazonaws.com/Tos.pdf",
    ADMIN_PASSWORD: "123123",
    TOFES4_URL: "http://tofes4.co.il/thank-you",
    ESIGN_DOCUMENTS_MANAGEMENT_URL: "http://esign.blirokratia.com/admin",
    ESIGN_URL: "http://esign.blirokratia.com",
    PSPDFKIT_LICENSE_KEY: "E93LitQrkt0a80ezuxDRpV4COHLXBseVvyjGHjmC_FQXhNxp4pTTyIaBUJyJCpO-Ez6K7Id1If4yhVC6CRPXODNMpQ4W2jduEOPkt9wMESv-zTyKlHMVS9SE0HVqbHszVi5tsjgZFF8t2t3IMErWYZAq_OSDNKVcKyoQQEKae3KmtplFpreH5-2eBTz4BUz4pAokG64YnWo0OGsRt3Slt9YTrY0qKnYIhosCp2C_7Z0TqHhcqMmSznkb3ww0QUweid3Jq8-xhQ6IGYPIoEQTiE2C0fhAHVZ8z0744mvj3uNuOzRgYknAjaejb-RmBC6urT1UJSgG2ljSvDufFTAqW1KJxnmsLvZjtUhwL0fmmuLnvFztRbP8E01C1jVAXj86w2-O_y4ViBSd5qoiyCLpK-_2wEZ90RVRcQkh1iDUknf3LLJX-Emvxlxvz9Dqg6wSoDl-BtrbZlzrCqftAbdoQjEB5x7XUGmXecKhqFNOpEHg0Z4vgV9DrsbFtsKRaBziBDGdm7uk94e9e-3gAVGxSCCqbsyncSbSt9UHszMhYgDDUAdnkE_zBQTzJBJtt_S17I850O78MWEP-VzIrk1js2k0cpZ7rjEaMOErTXjOev1mAglNCvfqHhGryhFuD3w0OLj3agrkiMqLzTOtdIXYtvpAoWiYY5zca3b7ZDrBRzU="
    //PSPDFKIT_LICENSE_KEY: "fVbP0d_oDT8mw7NZV5UF68DdKcRj_PI87gbby1QbBOiOgXoU9FGc7gR6NnMs2Zb6xGK02WdvhKZ8y3PqaeWMA6lUnQPWmOQ52P3UpuFhytBRXvj0qq_mmdd1uhfHXE6uxUAeeQ9qcujGF7uK8DhOOh0Xed86FnAtMiUDIuRjMJv9yHYPbuZSdogeDhFI18kl-BeDs0dfWEsS0skUs2udpD1gQbO5bvAnxETV0Xj_YDsZ5zToFBru_T6j9yx9riwgpl0JLt8rTYH6-Wvg7enZ8S8odH0"
};
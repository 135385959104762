import axios from 'axios';
import { ENV } from '../config';
const API_PORT = process.env.REACT_APP_API_PORT || 5000;

const ENVIRONMENTS = {
    DEVELOPMENT: 'DEV',
    PRODUCTION: 'PROD'
};

//const CURR_ENV = process.env.REACT_APP_ENVIRONMENT_MODE || ENVIRONMENTS.PRODUCTION;
const CURR_ENV = ENVIRONMENTS.PRODUCTION;

//export const SERVER_URL = 'http://graveapi.project-demo.info:3009';
export const SERVER_URL = CURR_ENV === ENVIRONMENTS.DEVELOPMENT ? 'http://localhost:5001' : ENV.API_URL;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const instance = axios.create({
    baseURL: SERVER_URL
});

export const HttpRequestTypes = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
};

export const HEADERS_OPTIONS = {
    FORM_DATA: { 'Content-Type': 'multipart/form-data' }
};

export const HttpRequest = async (
    url,
    method,
    data = null,
    token = null,
    additionalHeaders = {}
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        ...additionalHeaders
    };

    try {
        switch (method) {
            case HttpRequestTypes.POST:
            case HttpRequestTypes.PUT:
                return await instance({ url, method, data, headers });

            case HttpRequestTypes.GET:
            case HttpRequestTypes.DELETE:
            default:
                return await instance({ url, method, headers });
        }
    } catch (error) {
        console.error('Error during HTTP request:', error);
        throw error;
    }
};

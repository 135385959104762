const scripts = [
  {
    name: "pdfjsLib",
    src: "https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.min.js",
  },
  {
    name: "PDFLib",
    src: "https://unpkg.com/pdf-lib@1.4.0/dist/pdf-lib.min.js",
  },
  {
    name: "download",
    src: "https://unpkg.com/downloadjs@1.4.7",
  },
  { name: "makeTextPDF", src: "/makeTextPDF.js" },
];

const path = require("path");

const assets = {};
export function getAsset(name) {
  if (assets[name]) return assets[name];
  const script = scripts.find((s) => s.name === name);
  if (!script) throw new Error(`Script ${name} not exists.`);
  return prepareAsset(script);
}

export function prepareAsset({ name, src }) {
  if (assets[name]) return assets[name];
  assets[name] = new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(window[name]);
      console.log(`${name} is loaded.`);
    };
    script.onerror = () => {
      reject(`The script ${name} didn't load correctly.`);
      alert(
        `Some scripts did not load correctly. Please reload and try again.`
      );
    };
    document.body.appendChild(script);
  });
  return assets[name];
}

export default function prepareAssets() {
  scripts.forEach(prepareAsset);
}

// out of the box fonts
const fonts = {
  Courier: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 6;
    },
  },
  Helvetica: {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 10;
    },
  },
  "Times-Roman": {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
  "Sans-Serif": {
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
  rubik: {
    src: path.join(__dirname, "Rubik-VariableFont_wght.ttf"), //added this 3 fonts that i suppose supoort hebrew
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
  assistant: {
    src: path.join(__dirname, "Assistant-Regular.ttf"),
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
  david: {
    src: path.join(__dirname, "David.ttf"),
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2 + size / 7;
    },
  },
};
// Available fonts
export const Fonts = {
  ...fonts,
  標楷體: {
    src: "/CK.ttf", // 9.9 MB
    correction(size, lineHeight) {
      return (size * lineHeight - size) / 2;
    },
  },
};

export function fetchFont(name) {
  // hard coded assitant
  if (!fonts[name].src) return fonts[name]; // changed it from if !fonts[name] so when there is src use it

  const font = Fonts[name];
  console.log(font);

  if (!font) throw new Error(`Font '${name}' not exists.`);
  fonts[name] = fetch(font.src)
    .then((r) => r.arrayBuffer())
    .then((fontBuffer) => {
      const fontFace = new FontFace(name, fontBuffer);
      fontFace.display = "swap";
      fontFace.load().then((a) => {
        document.fonts.add(fontFace);
      });
      return {
        ...font,
        buffer: fontBuffer,
      };
    });
  // console.log("why iam here");
  // return fonts[name];//comment this did not why it is there
}

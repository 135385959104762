import React from "react";
import MainRouter from "./components/MainRouter";

import "./assets/styles/app.css";
import { SocketContext, socket } from "./contex/socket";

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <MainRouter />
    </SocketContext.Provider>
  );
}

export default App;

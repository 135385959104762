import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SCREEN_NAMES } from '../constants';

import PSPDFKITDocument from '../screens/Documents/PSPDFKITDocument';
// import PDFKITDocument from "../screens/Documents/PDFKITDocument";
import LoadingSpinner from './LoadingSpinner';
const PdfEditorDocument = lazy(() => import('../screens/Documents/PdfEditorDocument'));
const CreateSession = lazy(() => import('../screens/Documents/CreateSession'))

const AppRouter = (props) => {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route exact path={SCREEN_NAMES.HOME_SCREEN} element={<PdfEditorDocument />} />
                    <Route exact path={SCREEN_NAMES.CREATE_SESSION} element={<CreateSession />} />
                    <Route exact path={SCREEN_NAMES.REF} element={<PSPDFKITDocument />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRouter;
